// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-applications-index-js": () => import("./../../../src/pages/applications/index.js" /* webpackChunkName: "component---src-pages-applications-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturing-index-js": () => import("./../../../src/pages/manufacturing/index.js" /* webpackChunkName: "component---src-pages-manufacturing-index-js" */),
  "component---src-pages-old-projects-index-js": () => import("./../../../src/pages/old-projects/index.js" /* webpackChunkName: "component---src-pages-old-projects-index-js" */),
  "component---src-pages-parallax-js": () => import("./../../../src/pages/parallax.js" /* webpackChunkName: "component---src-pages-parallax-js" */),
  "component---src-pages-production-index-js": () => import("./../../../src/pages/production/index.js" /* webpackChunkName: "component---src-pages-production-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

